import "./App.css";
import "./Bitcoin.css";
import QRCode from "react-qr-code";

const {
  REACT_APP_BTCADDRESS,
  REACT_APP_LNTIPS,
  REACT_APP_GITHUB,
  REACT_APP_TWITCH,
  REACT_APP_YOUTUBE,
  REACT_APP_EMAIL,
  REACT_APP_PRESENTATION,
  REACT_APP_PROJECT_TRUCOSHI,
} = process.env;

const title = window.location.hostname.replace("www.", "");

document.title = title;

function App() {
  return (
    <div className="App">
      <div>
        <a
          href={
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
          }
        >
          <h5>{title}</h5>
        </a>
        <div>
          {REACT_APP_PRESENTATION.split("\n").map((s) => (
            <h5 key={s}>{s}</h5>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1024px",
          margin: "0 auto",
        }}
      >
        <ul id="links">
          <li>
            <a
              href={
                process.env[
                  `REACT_APP_${title.toUpperCase().replace(".", "_")}_TWITTER`
                ]
              }
            >
              twitter
            </a>
          </li>
          <li>
            <a href={REACT_APP_TWITCH}>twitch</a>
          </li>
          <li>
            <a href={REACT_APP_YOUTUBE}>youtube</a>
          </li>
        </ul>
        <ul id="projects">
          <li>
            <a href={REACT_APP_GITHUB}>github</a>
          </li>
          <li>
            <a href={REACT_APP_PROJECT_TRUCOSHI}>trucoshi</a>
          </li>
          <li>
            <a href={`mailto:${REACT_APP_EMAIL}`}>email</a>
          </li>
        </ul>
      </div>
      <div id="bitcoin">
        <div id="bitcoin_svg">
          <a href={`bitcoin:${REACT_APP_BTCADDRESS}`}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="75px"
              height="75px"
              viewBox="100 100 400 400"
              xmlSpace="preserve"
            >
              <path
                className="path"
                fill="#000000"
                d="M446.089,261.45c6.135-41.001-25.084-63.033-67.769-77.735l13.844-55.532l-33.801-8.424l-13.48,54.068
			c-8.896-2.217-18.015-4.304-27.091-6.371l13.568-54.429l-33.776-8.424l-13.861,55.521c-7.354-1.676-14.575-3.328-21.587-5.073
			l0.034-0.171l-46.617-11.64l-8.993,36.102c0,0,25.08,5.746,24.549,6.105c13.689,3.42,16.159,12.478,15.75,19.658L208.93,357.23
			c-1.675,4.158-5.925,10.401-15.494,8.031c0.338,0.485-24.579-6.134-24.579-6.134l-9.631,40.468l36.843,9.188
			c8.178,2.051,16.209,4.19,24.098,6.217l-13.978,56.17l33.764,8.424l13.852-55.571c9.235,2.499,18.186,4.813,26.948,6.995
			l-13.802,55.309l33.801,8.424l13.994-56.061c57.648,10.902,100.998,6.502,119.237-45.627c14.705-41.979-0.731-66.193-31.06-81.984
			C425.008,305.984,441.655,291.455,446.089,261.45z M368.859,369.754c-10.455,41.983-81.128,19.285-104.052,13.589l18.562-74.404
			C306.28,314.65,379.774,325.975,368.859,369.754z M379.302,260.846c-9.527,38.187-68.358,18.781-87.442,14.023l16.828-67.489
			C327.767,212.14,389.234,221.02,379.302,260.846z"
              />
            </svg>
          </a>
        </div>
        <div id="bitcoin_message" />
        <div id="bitcoin_qr" title="Scan the QR">
          <QRCode
            size={196}
            href={`bitcoin:${REACT_APP_BTCADDRESS}`}
            alt="Buy me a coffee"
            value={REACT_APP_BTCADDRESS}
          />
        </div>
        <p>
          <a href={REACT_APP_LNTIPS}>LN TIPS ⚡</a>
        </p>
      </div>
    </div>
  );
}

export default App;
